<template>
  <NuxtLink
    v-if="paper.Url"
    :to="{ name: 'paper', params: { paper: paper.Url } }"
    class="grid"
  >
    <div
      class="bg-blue-light rounded-full overflow-hidden w-24 h-24 mr-auto ml-auto"
    >
      <CommonImageLoader
        :src="`https://${config.public.site.legacyurl}/images/altinget8/covers/paper-cover-${paper.RecordId}.png`"
        :width="128"
        :height="128"
        :alt="'Altinget ' + paper.Name"
        class="mr-auto ml-auto"
      />
    </div>
    <div class="text-center flex items-center justify-center gap-1 mt-1">
      <img
        class="w-3 h-3 inline-block mb-0.5"
        :alt="'Altinget ' + paper.Name"
        src="/A_Logomark.svg"
      />
      <span class="inline-block list-title-xxxs">
        {{ paper.Name }}
      </span>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { ContentPaper } from '~/typesManual/content_api/paper'

const config = useRuntimeConfig()

defineProps<{
  paper: ContentPaper
}>()
</script>
