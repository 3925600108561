<template>
  <div
    class="mb-10 hidden lg:grid gap-10 grid-flow-col auto-cols-max place-content-center"
  >
    <PaperTeaserCompact
      v-for="item in items"
      :key="item.RecordId"
      :paper="item"
    />
  </div>
</template>

<script setup lang="ts">
const indexStore = useIndexStore()
const items = computed(() => indexStore.activePapers)
</script>

<style lang="scss" scoped></style>
