<template>
  <div>
    <NuxtLink
      v-if="typeof item === 'object'"
      :to="{
        name: indexStore.currentPaperSlug
          ? 'paper_decisionchain_id'
          : 'decisionchain_id',
        params: indexStore.currentPaperSlug
          ? {
              paper: indexStore.currentPaperSlug,
              id: item.UrlKey,
            }
          : { id: item.UrlKey },
      }"
      class="flex"
      :class="[
        type === 'slider'
          ? 'h-56 md:h-70 shrink-0 max-w-full flex-col p-3 rounded-xl'
          : placement === 'main' && !false
          ? 'p-4 rounded-xl'
          : !false
          ? 'p-2 rounded-lg'
          : '',
        !false || type === 'slider' ? 'border-gray-300 border' : null,
      ]"
    >
      <div :class="type === 'slider' ? 'flex flex-col h-full' : null">
        <div
          class="text-black"
          :class="
            type === 'slider'
              ? 'list-title-xs !font-light mt-2 hyphens-auto'
              : placement === 'main'
              ? 'list-title-s !font-light'
              : 'list-title-xxxs'
          "
          v-html="item.Title"
        />
        <div
          class="opacity-35 list-label flex items-center"
          :class="
            type === 'slider'
              ? 'mt-auto'
              : placement === 'main'
              ? 'mt-4'
              : 'mt-2'
          "
        >
          <template v-if="type === 'slider' || placement === 'main'">
            <img
              src="~/assets/icons/calendar.svg?url"
              :alt="$t('Addresse')"
              class="w-4 h-4 inline-block mr-1 ml-3"
            /><span>{{ item.Time }}</span>
          </template>
        </div>
      </div>
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">
const indexStore = useIndexStore()

defineProps({
  index: {
    type: Number,
    default: 0,
  },
  item: {
    type: Object,
    default: null,
  },
  type: {
    type: String,
    default: 'default',
  },
  placement: {
    type: String,
    default: 'side',
  },
})
</script>

<style scoped>
hyphens-auto {
  hyphens: auto;
}
</style>
